<!-- 提单信息 -->
<template>
  <div>
    <el-dialog
      title=""
      :visible="true"
      width="950px"
      :before-close="handleClose"
    >
      <div class="hoho-form-container">
        <el-form
          :model="customsModel"
          label-width="120px"
          :rules="rules"
          ref="limitForm"
          :inline="true"
        >
          <el-form-item label="代理商" prop="tempagencycode">
            <el-select
              v-model="customsModel.tempagencycode"
              filterable
              multiple
              placeholder="请选择代理商"
              :disabled="customsModel.code ? true : false"
            >
              <el-option
                v-for="item in agencys"
                :key="item.agency_code"
                :label="item.agency_name"
                :value="item.agency_code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="提单号" prop="cnumber">
            <el-input
              v-model="customsModel.cnumber"
              :disabled="customsModel.code ? true : false"
              placeholder="请输入提单号"
            ></el-input>
          </el-form-item>
          <el-form-item label="航班日期" prop="flighttime">
            <el-date-picker
              v-model="customsModel.flighttime"
              type="date"
              placeholder="请选择航班日期"
              value-format="yyyy-MM-dd"
              @change="changeFlightTime"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="过期时间" prop="expirationtime">
            <el-date-picker
              v-model="customsModel.expirationtime"
              type="date"
              placeholder="请选择过期时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="航班号" prop="flightnumber">
            <el-input
              v-model="customsModel.flightnumber"
              placeholder="请输入航班号"
            ></el-input>
          </el-form-item>
          <el-form-item label="航线" prop="airline">
            <el-input
              v-model="customsModel.airline"
              placeholder="请输入航线"
            ></el-input>
          </el-form-item>
          <!-- 编辑代理商提单 -->
          <template v-if="isAgencyCustomsbill">
            <el-form-item label="申告番号" prop="reportcode">
              <el-input
                v-model="customsModel.reportcode"
                placeholder="请输入申告番号"
              ></el-input>
            </el-form-item>
            <el-form-item label="申告金额" prop="reportprice">
              <el-input
                v-model="customsModel.reportprice"
                placeholder="请输入申告金额"
              ></el-input>
            </el-form-item>
          <el-form-item label="申告日期" prop="reportdate">
            <el-date-picker
              v-model="customsModel.reportdate"
              type="date"
              placeholder="请选择申告日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
            <el-form-item label="实重" prop="realweight">
              <el-input
                v-model="customsModel.realweight"
                placeholder="请输入实重"
                ><template slot="append">kg</template></el-input
              >
            </el-form-item>
            <el-form-item label="抛重" prop="bulkweight">
              <el-input
                v-model="customsModel.bulkweight"
                placeholder="请输入抛重"
                ><template slot="append">kg</template></el-input
              >
            </el-form-item>
            <el-form-item label="箱数" prop="boxqty">
              <el-input
                v-model="customsModel.boxqty"
                placeholder="请输入箱数"
              ></el-input>
            </el-form-item>
            <el-form-item label="票数" prop="poll">
              <el-input
                v-model="customsModel.poll"
                placeholder="请输入票数"
              ></el-input>
            </el-form-item>
          </template>
          <!-- 创建提单 -->
          <template v-else>
            <el-form-item label="预定重量" prop="weight">
              <el-input
                v-model="customsModel.weight"
                placeholder="请输入预定重量"
                ><template slot="append">kg</template></el-input
              >
            </el-form-item>
            <el-form-item label="提单实重" prop="estimateweight">
              <el-input
                v-model="customsModel.estimateweight"
                placeholder="请输入提单实重"
                ><template slot="append">kg</template></el-input
              >
            </el-form-item>
            <el-form-item label="提单抛重" prop="estimatethrowweight">
              <el-input
                v-model="customsModel.estimatethrowweight"
                placeholder="请输入提单抛重"
                ><template slot="append">kg</template></el-input
              >
            </el-form-item>
            <el-form-item label="提单箱数" prop="estimatebox">
              <el-input
                v-model="customsModel.estimatebox"
                placeholder="请输入提单箱数"
              ></el-input> </el-form-item
          ></template>

          <el-form-item label="清关场地" prop="customsplace">
            <el-select
              v-model="customsModel.customsplace"
              filterable
              clearable
              placeholder="请选择清关场地"
            >
              <el-option
                v-for="item in place"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订舱人" prop="booker">
            <el-select
              v-model="customsModel.bookerid"
              filterable
              clearable
              placeholder="请选择订舱人"
            >
              <el-option
                v-for="item in bookers"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="isAgencyCustomsbill">
            <el-form-item label="输出许可附件">
              <element-upload-pdf
                accept=".pdf"
                explain="只能上传pdf文件"
                @SETFILE="handleSetFile"
                @delpicture="handleDelFile"
                filetype="2"
              ></element-upload-pdf>
              <div
                class="file-item"
                v-if="licenceattrTmp && licenceattrTmp.length"
              >
                <div
                  class="file"
                  v-for="(item, index) in licenceattrTmp"
                  :key="index"
                >
                  <div>
                    {{ index + 1 }}
                    <a :href="item.url" target="_blank"
                      ><i class="icon iconfont icon-PDF red"></i
                      >{{ item.name }}</a
                    >
                  </div>
                  <i
                    class="icon iconfont icon-shanchu2 red"
                    @click="handleDelFile(index, 2)"
                  ></i>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="显示输出许可">
              <el-switch
                v-model="customsModel.showlicence"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="提单附件">
              <element-upload-pdf
                accept=".pdf"
                explain="只能上传pdf文件"
                @SETFILE="handleSetFile"
                @DELFILE="handleDelFile"
                filetype="1"
              ></element-upload-pdf>
              <div class="file-item" v-if="attrTmp && attrTmp.length">
                <div class="file" v-for="(item, index) in attrTmp" :key="index">
                  <div>
                    {{ index + 1 }}
                    <a :href="item.url" target="_blank"
                      ><i class="icon iconfont icon-PDF red"></i
                      >{{ item.name }}</a
                    >
                  </div>
                  <i
                    class="icon iconfont icon-shanchu2 red"
                    @click="handleDelFile(index, 1)"
                  ></i>
                </div>
              </div>
            </el-form-item>
          </template>
          <el-form-item label="是否生效">
            <el-switch
              v-model="state"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入内容"
              v-model="customsModel.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { vxRule, rules } from '@/utils/validator'
import ElementUploadPdf from './element-upload-pdf.vue'
import Customsbill from 'api/customsbill'
import commonApi from 'api/common'
import { GetToken } from 'utils/auth'
export default {
  components: { ElementUploadPdf },
  name: '',
  props: {
    customsModel: {
      type: Object,
      default: function () {
        return {
          id: '',
          code: '', // 提单号编码
          cnumber: '', // 提单号
          flighttime: '', // 航班日期
          flightnumber: '', // 航班号
          airline: '', // 航线
          state: 0, // 状态
          remark: '', // 备注
          tempagencycode: [], // 代理码
          agencycode: '', // 代理码
          expirationtime: '', // 过期时间
          weight: '', // 预定重量
          realweight: '', // 实重
          bulkweight: '', //  抛重
          boxqty: '', // 箱数
          attr: '', // 提单附件
          customsplace: '', // 清关场地
          licenceattr: '', // 出口许可
          showlicence: 0, // 是否显示许可 1显示 0 隐藏
          bookerid: '', // 订舱人
          reportcode: '', // 申告番号
          reportdate: '', // 申告时间
          reportprice: '', // 申告金额
          estimateweight: '', // 提单实重
          estimatethrowweight: '', // 提单抛重
          estimatebox: '', // 提单箱数
          poll: '' // 票数
        }
      }
    }
  },
  data() {
    let verifyPayFee = (rule, value, callback) => {
      if (value) {
        let { result, errMsg } = rules.Number(value)
        if (!result) {
          callback()
        } else {
          callback(new Error(errMsg))
        }
      } else {
        callback()
      }
    }
    return {
      rules: {
        realweight: vxRule(false, 'Float2'),
        bulkweight: vxRule(false, 'Float2'),
        boxqty: vxRule(false, 'Int'),
        poll: vxRule(false, 'Int'),
        weight: vxRule(false, 'Float2'),
        estimateweight: vxRule(false, 'Float2'),
        estimatethrowweight: vxRule(false, 'Float2'),
        estimatebox: vxRule(false, 'Int'),
        tempagencycode: vxRule(true, null, 'change', '请选择代理商'),
        cnumber: vxRule(true, null, '', '请输入提单号'),
        flighttime: vxRule(true, null, '', '请选择过期时间'),
        expirationtime: vxRule(true, null, '', '请选择过期时间')
      },
      isAgencyCustomsbill: false, // 是否是编辑代理商提单
      state: 1, // 提单状态
      attrTmp: [], // 临时提单附件
      licenceattrTmp: [], // 临时出口许可
      agencys: [], // 代理商
      place: [], // 清关场地
      bookers: [] // 订舱人
    }
  },
  computed: {},
  watch: {},
  created() {
    console.log('customsModel.agencycode:', this.customsModel)
    if (this.customsModel.agencycode) {
      this.customsModel.tempagencycode = [this.customsModel.agencycode]
      this.state = this.customsModel.state
      this.isAgencyCustomsbill = true
    }
    if (this.customsModel.attr) {
      this.attrTmp = JSON.parse(this.customsModel.attr)
      this.customsModel.attr = this.attrTmp
    }
    if (this.customsModel.licenceattr) {
      this.licenceattrTmp = JSON.parse(this.customsModel.licenceattr)
      this.customsModel.licenceattr = this.licenceattrTmp
    }

    console.log('model:', this.customsModel)
    this.getAgencys()
    this.getBookers()
    this.getPlaceNames()
  },
  mounted() {},
  destroyed() {},
  methods: {
    addYear() {
      this.customsModel.expirationtime = this.$dayjs().year(
        new Date().getFullYear() + 1
      )
    },
    // 关闭
    handleClose() {
      this.$emit('FUNCLOSELIMIT')
    },
    // 提交
    handleSubmit() {
      this.$refs.limitForm.validate((valid) => {
        if (valid) {
          this.customsModel.agencycode =
            this.customsModel.tempagencycode.join(',')
          Customsbill.addCustomsBill({
            ...this.customsModel,
            attr: JSON.stringify(this.customsModel.attr),
            licenceattr: JSON.stringify(this.customsModel.licenceattr),
            state: this.state,
            token: GetToken()
          }).then((res) => {
            if (res.success) {
              this.$emit('FUNCLOSELIMIT', true)
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 删除图片
    handleDelFile(index, type) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          if (type == 1) {
            this.attrTmp.splice(index, 1)
            this.customsModel.attr = this.attrTmp
          } else {
            this.licenceattrTmp.splice(index, 1)
            this.customsModel.licenceattr = this.licenceattrTmp
          }
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {})
    },
    // 上传图片赋值
    handleSetFile(fileinfo) {
      if (fileinfo.filetype == 1) {
        if (this.customsModel.attr) {
          this.attrTmp = [].concat(this.customsModel.attr)
          this.attrTmp.push(fileinfo)
          this.customsModel.attr = this.attrTmp
        } else {
          this.attrTmp.push(fileinfo)
          this.customsModel.attr = this.attrTmp
        }
      } else {
        if (this.customsModel.licenceattr) {
          this.licenceattrTmp = [].concat(this.customsModel.licenceattr)
          this.licenceattrTmp.push(fileinfo)
          this.customsModel.licenceattr = this.licenceattrTmp
        } else {
          this.licenceattrTmp.push(fileinfo)
          this.customsModel.licenceattr = this.licenceattrTmp
        }
      }
      this.$forceUpdate()
      console.log('model: ', this.customsModel)
    },
    // 获取代理商
    getAgencys() {
      commonApi.getAgencys({}, false).then((res) => {
        if (res.success) {
          this.agencys = res.record
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取订舱人
    getBookers() {
      Customsbill.getBookerList({
        token: GetToken()
      }).then((res) => {
        if (res.success) {
          this.bookers = res.record
        }
      })
    },
    // 获取清关场地
    getPlaceNames() {
      Customsbill.getLogisticsNames({
        token: GetToken()
      }).then((res) => {
        if (res.success) {
          this.place = res.record
        }
      })
    },
    // 选择航班日期
    changeFlightTime(val) {
      this.$set(this.customsModel, 'expirationtime', val)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.file-item {
  margin-top: 20px;
  background: #f8f8f8;
  padding: 10px;
  .file {
    display: flex;
    justify-content: space-between;
    & > div {
      i {
        margin-right: 5px;
      }
      a {
        margin-left: 5px;
      }
    }
  }
}
::v-deep {
  .el-input,
  .el-textarea {
    width: 300px;
  }
  .el-upload-dragger {
    width: 300px;
  }
  .preview-images {
    width: 300px !important;
    margin-left: -300px !important;
  }
  .el-image {
    width: 100px !important;
    height: 100px !important;
  }
  .el-upload__tip {
    line-height: 0;
  }
}
</style>
