import { post, get,upload,netpost } from '../plugins/request'
import config from '../config/index';

export default {
  // 翻译文本
  translate(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/translate/translateText`, params)
  },
  // 上传
  uploadImage(params, loading = true) {
    Object.assign(params, { loading })
    return upload(`${config.APIURL}/upload/uploadImage`, params)
  },
  // 获取代理商
  getAgencys(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetAgencys`,params);
  },
  // 获取代理商子代理
  getAgencyChildren(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetAgencyChild`,params);
  },
  // 获取代理商子代理
  getAgencyChildByCode(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetAgencyChildByCode`,params);
  },
  // 获取枚举快递公司
  getDeliveryType(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetDeliveryType`,params);
  }
}
