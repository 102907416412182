import { netpost, netdownload } from '../plugins/request'

export default {
  setCustomsCnumber(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/SetCustomsCnumber`, params)
  },
  // 提单记录统计admin
  getCustomsBillListReports(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetCustomsBillListReports`, params)
  },
  // 提单记录统计导出admin
  downLoadCustomsbillReportsExcel(params, loading = true) {
    Object.assign(params, { loading })
    return netdownload(`/api/Logistics/DownLoadCustomsbillReportsExcel`, params)
  },
  // 提单记录admin
  getCustomsbillList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetCustomsBillList`, params)
  },
  // 提单明细记录admin
  getCustomsbillInfoList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetCustomsBillInfoList`, params)
  },
  // 提单记录agency
  getCustomsbillAgencyList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetCustomsBillAgencyList`, params)
  },
  // 编辑提单Invoice
  eidtCustomsbillInvoice(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/EidtCustomsbillInvoice`, params)
  },
  // 添加编辑提单
  addCustomsBill(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/AddCustomsBill`, params)
  },
  // 删除提单
  delCustomsBill(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/DelCustomsBill`, params)
  },
  // 提单附件
  customsBillAttr(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/CustomsBillAttr`, params)
  },
  // 编辑提单
  editCustomsBill(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/EditCustomsBill`, params)
  },
  // 清单场地
  getLogisticsNames(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetLogisticsNames`, params)
  },
  // 修改提单状态
  setCustomsBillState(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/SetCustomsBillState`, params)
  },
  // 添加编辑订舱人
  DownFile(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/DownFile`, params)
  },
  // 添加编辑订舱人
  DownFileAgency(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/DownFileAgency`, params)
  },
  //////////////////////////////// 订舱人
  // 有效订舱人列表
  getBookerList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetBookerList`, params)
  },

  // 分页订舱人列表
  getBookerByPage(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetBookerByPage`, params)
  },

  // 添加编辑订舱人
  setBooker(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/SetBooker`, params)
  },
  // 数据导出
  DownLoadCustomsBillData(params, loading = true) {
    Object.assign(params, { loading })
    return netdownload(`/api/Logistics/DownLoadCustomsBillData`, params)
  },
  // 屏蔽提单
  customsBillHidden(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/CustomsBillHidden`, params)
  }
}
