export default {
  data() {
    return {}
  },
  methods: {
    // table index
    calcIndex(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1
    }
  }
}
