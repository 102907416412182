<template>
  <div class="logistics-list">
    <div class="list-container">
      <div class="search-container">
        <div class="sc-item">
          <label class="sci-title">代理商</label>
          <el-select
            v-model="searchData.agency"
            filterable
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in agencys"
              :key="item.agency_code"
              :label="item.agency_name"
              :value="item.agency_code"
            >
            </el-option>
          </el-select>
        </div>
        <div class="sc-item">
          <label class="sci-title">提单号</label>
          <el-input
            placeholder="输入提单号模糊查询"
            v-model="searchData.cnumber"
            @keyup.enter.native="handleSearch"
            clearable
          >
          </el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">航班时间</label>
          <el-date-picker
            v-model="searchData.flightTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="sc-item">
          <label class="sci-title">启用状态</label>
          <el-checkbox-group v-model="searchData.state">
            <el-checkbox v-for="s in state" :label="s.key" :key="s.key">{{
              s.value
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sc-item">
          <label class="sci-title">是否屏蔽</label>
          <el-checkbox-group v-model="searchData.ishidden">
            <el-checkbox v-for="s in ishidden" :label="s.key" :key="s.key">{{
              s.value
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sc-item">
          <label class="sci-title">使用状态</label>
          <el-checkbox-group v-model="searchData.useState">
            <el-checkbox v-for="s in useState" :label="s.key" :key="s.key">{{
              s.value
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sc-item">
          <label class="sci-title">是否有提单</label>
          <el-checkbox-group v-model="searchData.hasAttr">
            <el-checkbox v-for="s in hasAttr" :label="s.key" :key="s.key">{{
              s.value
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sc-item">
          <label class="sci-title">是否有出口许可</label>
          <el-checkbox-group v-model="searchData.hasLicence">
            <el-checkbox v-for="s in hasLicence" :label="s.key" :key="s.key">{{
              s.value
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sc-item">
          <label class="sci-title">是否显示出口许可</label>
          <el-checkbox-group v-model="searchData.showLicence">
            <el-checkbox v-for="s in showLicence" :label="s.key" :key="s.key">{{
              s.value
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sc-item">
          <el-button @click="handleSearch" icon="el-icon-search" type="primary"
            >查询</el-button
          >
          <el-button @click="handleReset" icon="el-icon-refresh-left"
            >重置</el-button
          >
          <el-button icon="el-icon-plus" type="success" @click="handleAdd"
            >新增</el-button
          >
          <el-button type="warning" @click="handelExportExcel"
            >导出Excel</el-button
          >
          <template v-if="multipleSelection.length">
            <!-- <el-button type="success" @click="handleSetState('state', 1)"
              >启用</el-button
            >
            <el-button type="danger" @click="handleSetState('state', 0)"
              >禁用</el-button
            > -->
            <el-button type="success" @click="handleSetState('licence', 1)"
              >显示许可</el-button
            >
            <el-button type="danger" @click="handleSetState('licence', 0)"
              >隐藏许可</el-button
            >
            <el-button type="info" @click="handleDownLoadAttr('attr')"
              >下载提单</el-button
            >
            <el-button type="info" @click="handleDownLoadAttr('licence')"
              >下载许可</el-button
            >
          </template>
        </div>
      </div>
      <div class="table-container">
        <el-tabs v-model="activeName" type="card" @tab-click="handleTabClick">
          <el-tab-pane label="全部" name="default">
            <el-table
              :data="tableData"
              border
              header-cell-class-name="table-header"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="table-children-title">
                    <div class="agency">代理商</div>
                    <div class="usestate">使用状态</div>
                    <div class="state">状态</div>
                    <div class="state">是否屏蔽</div>
                    <div class="showlicence">许可状态</div>
                    <div class="licenceattr">输出许可</div>
                    <div class="reportcode">申告番号</div>
                    <div class="reportprice">申告金额</div>
                    <div class="reportprice">申告日期</div>
                    <div class="remark">备注</div>
                    <div class="useqty">使用次数</div>
                    <div class="poll">票数</div>
                    <div class="realweight">实重(kg)</div>
                    <div class="bulkweight">抛重(kg)</div>
                    <div class="boxqty">箱数</div>
                    <div class="operation"></div>
                  </div>
                  <div
                    class="table-children-body"
                    v-for="(item, index) in props.row.info"
                    :key="index"
                  >
                    <div class="agency">
                      {{ item.Agency ? item.Agency.agency_name : '' }}
                    </div>
                    <div class="usestate">
                      <el-tag :type="item.useqty > 0 ? 'success' : 'info'">{{
                        item.useqty > 0 ? '已使用' : '未使用'
                      }}</el-tag>
                    </div>
                    <div class="state">
                      <el-tag :type="item.state > 0 ? 'success' : 'info'">{{
                        item.state > 0 ? '有效' : '无效'
                      }}</el-tag>
                    </div>
                    <div class="state">
                      <el-tag :type="item.ishidden > 0 ? 'success' : 'info'">{{
                        item.ishidden > 0 ? '是' : '否'
                      }}</el-tag>
                    </div>
                    <div class="showlicence">
                      <el-tag
                        :type="item.showlicence > 0 ? 'success' : 'info'"
                        >{{ item.showlicence > 0 ? '显示' : '隐藏' }}</el-tag
                      >
                    </div>
                    <div class="licenceattr">
                      <template v-if="item.licenceattr">
                        <div class="pdf-items">
                          <div
                            class="attr-info"
                            v-for="(attr, index) in JSON.parse(
                              item.licenceattr
                            )"
                            :key="index"
                          >
                            <a
                              :href="attr.url"
                              target="_blank"
                              :title="attr.name"
                              :download="attr.name"
                            >
                              <i class="icon iconfont icon-PDF">{{
                                attr.name
                              }}</i>
                            </a>
                          </div>
                        </div>
                      </template>
                      <i
                        class="iconfont icon-shangchuan"
                        @click="handleEditAttr(item, 2)"
                        ><span>编辑许可</span></i
                      >
                    </div>
                    <div class="reportcode">{{ item.reportcode }}</div>
                    <div class="reportprice">
                      {{ item.reportprice | numFormat }}
                    </div>
                    <div class="reportprice">
                      {{ item.reportdate | dateFormat('YYYY-MM-DD') }}
                    </div>
                    <div class="remark">{{ item.remark }}</div>
                    <div class="useqty">{{ item.useqty }}</div>
                    <div class="poll">{{ item.poll }}</div>
                    <div class="realweight">{{ item.realweight }}</div>
                    <div class="bulkweight">{{ item.bulkweight }}</div>
                    <div class="boxqty">{{ item.boxqty }}</div>
                    <div class="operation">
                      <span class="edit" @click="handleEdit(item)">编辑</span>
                      <span class="del red" @click="handleDel(item)">删除</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="提单号" width="180">
                <template slot-scope="scope">
                    <div class="table-line-content">
                      <div class="content">
                        <el-input
                          v-if="editNumber.old == scope.row.cnumber"
                          v-model.trim="editNumber.new"
                          @blur="handleNumberChange()"
                        ></el-input>
                        <span v-else>
                          <span>
                            {{ scope.row.cnumber.slice(0, -4)}}
                            <span class="red bold">
                              {{scope.row.cnumber.slice(-4)}}
                            </span>
                          </span>
                        </span>
                      </div>
                      <i
                        class="el-icon-edit"
                        @click="handleEditNumber(scope.row.cnumber)"
                      ></i>
                    </div>
                  </template>



              </el-table-column>
              <el-table-column prop="flighttime" label="航班日期">
                <template slot-scope="scope">
                  <span>{{ scope.row.flighttime.slice(0, 10) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="过期日期">
                <template slot-scope="scope">
                  <span>{{ scope.row.expirationtime.slice(0, 10) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="flightnumber" label="航班号">
              </el-table-column>
              <el-table-column prop="airline" label="航线"> </el-table-column>
              <el-table-column prop="customsplaceName" label="清关场地">
              </el-table-column>
              <el-table-column prop="bookerName" label="订舱人">
              </el-table-column>
              <el-table-column prop="clearingweight" label="货代结算重量(kg)">
              </el-table-column>
              <el-table-column prop="useqty" label="使用次数" width="100px">
              </el-table-column>
              <el-table-column prop="weight" label="预定重量(kg)" width="120px">
              </el-table-column>
              <el-table-column
                prop="estimateweight"
                label="实重(kg)"
                width="81px"
              >
              </el-table-column>
              <el-table-column
                prop="estimatethrowweight"
                label="抛重(kg)"
                width="81px"
              >
              </el-table-column>
              <el-table-column prop="estimatebox" label="箱数" width="82px">
              </el-table-column>
              <!-- <el-table-column label="创建日期" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.createtime.slice(0, 10) }}</span>
            </template>
          </el-table-column> -->
              <el-table-column fixed="right" label="提单附件" width="130">
                <template slot-scope="scope">
                  <template v-if="scope.row.attr">
                    <div
                      class="attr-info"
                      v-for="(attr, index) in JSON.parse(scope.row.attr)"
                      :key="index"
                    >
                      <a :href="attr.url" target="_blank">
                        <i class="icon iconfont icon-PDF">{{ attr.name }}</i>
                      </a>
                    </div>
                  </template>
                  <i
                    class="iconfont icon-shangchuan"
                    @click="handleEditAttr(scope.row, 1)"
                    ><span>编辑提单</span></i
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="详情列表" name="list">
            <el-table
              ref="multipleTable"
              :data="tableData"
              border
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
            >
              <el-table-column fixed type="selection" width="55">
              </el-table-column>
              <el-table-column
                fixed
                type="index"
                :index="calcIndex"
                label="序号"
                width="50"
              >
              </el-table-column>
                <el-table-column label="航班日期" width="120">
                  <template slot-scope="scope">
                    <span>{{ scope.row.flighttime.slice(0, 10) }}</span>
                  </template>
                </el-table-column>
                <el-table-column fixed label="提单号" width="150">
                  <template slot-scope="scope">
                    <div class="table-line-content">
                      <div class="content">
                        <el-tooltip placement="right" effect="light">
                          <div slot="content" class="bill-invoice">
                            <div class="title">INVOICE</div>
                            <div>
                              预定<span>{{ scope.row.weight }}</span>
                            </div>
                            <div>
                              箱数<span>{{ scope.row.boxqty }}</span>
                            </div>
                            <div>
                              实重<span>{{ scope.row.realweight }}</span>
                            </div>
                            <div>
                              抛重<span>{{ scope.row.bulkweight }}</span>
                            </div>
                            <div>
                              票数<span>{{ scope.row.poll }}</span>
                            </div>
                            <div>
                              备注<span>{{ scope.row.remark }}</span>
                            </div>
                          </div>
                          <span
                            >{{ scope.row.cnumber.slice(0, -4)
                            }}<span class="red bold">{{
                              scope.row.cnumber.slice(-4)
                            }}</span></span
                          >
                        </el-tooltip>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  fixed
                  prop="Agency.agency_name"
                  label="代理商"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column label="状态" width="80">
                  <template slot-scope="scope">
                    <el-tag :type="scope.row.state > 0 ? 'success' : 'info'">{{
                      scope.row.state > 0 ? '有效' : '无效'
                    }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="bookerName"
                  show-overflow-tooltip
                  label="订舱人"
                >
                </el-table-column>
                <el-table-column label="提单附件">
                  <template slot-scope="scope">
                    <div class="table-line-content">
                      <div class="content">
                        <template v-if="scope.row.attr">
                          <div
                            class="attr-info"
                            v-for="(attr, index) in JSON.parse(scope.row.attr)"
                            :key="index"
                          >
                            <a :href="attr.url" target="_blank">
                              <i class="icon iconfont icon-PDF info"></i>
                            </a>
                          </div>
                        </template>
                      </div>
                      <i
                        class="el-icon-edit"
                        @click="handleEditAttr(scope.row, 1)"
                      ></i>
                    </div>
                  </template>
                </el-table-column>
              <!-- <el-table-column label="提单" align="center">
              </el-table-column> -->
              <el-table-column label="INVOICE" align="center">
                <el-table-column prop="weight" label="预定重量(kg)">
                </el-table-column>
                <el-table-column label="箱数">
                  <template slot-scope="scope">
                    <div class="table-line-content">
                      <div class="content">
                        <el-input
                          v-if="
                            editModel.key == scope.row.id &&
                            editModel.propName == 'boxqty'
                          "
                          v-model="scope.row.boxqty"
                          @change="handlePropChange(scope.row, 'boxqty')"
                        ></el-input>
                        <span v-else>{{ scope.row.boxqty }}</span>
                      </div>
                      <i
                        class="el-icon-edit"
                        @click="eidtProp(scope.row, 'boxqty')"
                      ></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="实重(kg)">
                  <template slot-scope="scope">
                    <div class="table-line-content">
                      <div class="content">
                        <el-input
                          v-if="
                            editModel.key == scope.row.id &&
                            editModel.propName == 'realweight'
                          "
                          v-model="scope.row.realweight"
                          @change="handlePropChange(scope.row, 'realweight')"
                        ></el-input>
                        <span v-else>{{ scope.row.realweight }}</span>
                      </div>
                      <i
                        class="el-icon-edit"
                        @click="eidtProp(scope.row, 'realweight')"
                      ></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="抛重(kg)">
                  <template slot-scope="scope">
                    <div class="table-line-content">
                      <div class="content">
                        <el-input
                          v-if="
                            editModel.key == scope.row.id &&
                            editModel.propName == 'bulkweight'
                          "
                          v-model="scope.row.bulkweight"
                          @change="handlePropChange(scope.row, 'bulkweight')"
                        ></el-input>
                        <span v-else>{{ scope.row.bulkweight }}</span>
                      </div>
                      <i
                        class="el-icon-edit"
                        @click="eidtProp(scope.row, 'bulkweight')"
                      ></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="票数">
                  <template slot-scope="scope">
                    <div class="table-line-content">
                      <div class="content">
                        <el-input
                          v-if="
                            editModel.key == scope.row.id &&
                            editModel.propName == 'poll'
                          "
                          v-model="scope.row.poll"
                          @change="handlePropChange(scope.row, 'poll')"
                        ></el-input>
                        <span v-else>{{ scope.row.poll }}</span>
                      </div>
                      <i
                        class="el-icon-edit"
                        @click="eidtProp(scope.row, 'poll')"
                      ></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="remark"
                  label="备注"
                  show-overflow-tooltip
                >
                </el-table-column>
              </el-table-column>
              <el-table-column label="输出许可书" align="center">
                <el-table-column fixed="right" label="输出许可">
                  <template slot-scope="scope">
                    <div class="table-line-content">
                      <div class="content">
                        <template v-if="scope.row.licenceattr">
                          <div
                            class="attr-info"
                            v-for="(attr, index) in JSON.parse(
                              scope.row.licenceattr
                            )"
                            :key="index"
                          >
                            <a :href="attr.url" target="_blank">
                              <i class="icon iconfont icon-PDF info"></i>
                            </a>
                          </div>
                        </template>
                      </div>
                      <i
                        class="el-icon-edit"
                        @click="handleEditAttr(scope.row, 2)"
                      ></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="许可状态" width="80">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.showlicence"
                      active-color="#13ce66"
                      inactive-color="#ccc"
                      :active-value="1"
                      :inactive-value="0"
                      @change="handleLicenceChange(scope.row)"
                    >
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="是否屏蔽" width="80">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.ishidden"
                      active-color="#13ce66"
                      inactive-color="#ccc"
                      :active-value="1"
                      :inactive-value="0"
                      @change="handleHiddenChange(scope.row)"
                    >
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="申告日期">
                  <template slot-scope="scope">
                    {{ scope.row.reportdate | dateFormat('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
                <el-table-column prop="reportcode" label="申告番号">
                </el-table-column>
                <el-table-column label="申告金额">
                  <template slot-scope="scope">
                    {{ scope.row.reportprice | numFormat }}
                  </template>
                </el-table-column>
              </el-table-column>
              <!-- <el-table-column label="过期日期" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row.expirationtime.slice(0, 10) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="flightnumber" label="航班号" width="120">
              </el-table-column>
              <el-table-column prop="airline" label="航线" width="160">
              </el-table-column>
              <el-table-column
                prop="customsplaceName"
                label="清关场地"
                width="150"
              >
              </el-table-column>
              <el-table-column prop="useqty" label="使用次数" width="100px">
              </el-table-column> -->
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="page-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="pageLayout"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
    <customs-bill-detail
      @FUNCLOSELIMIT="handleCloseCustomsDetail"
      :customsModel="detailModel"
      v-if="showCustomsBill"
    ></customs-bill-detail>
    <customsbill-attr
      v-if="showEidtAttr"
      @FUNCLOSEATTR="handleCloseEditAttr"
      :filetype="editAttrModel.filetype"
      :cnumber="editAttrModel.cnumber"
      :code="editAttrModel.code"
      :files="editAttrModel.files"
      :showlicence="editAttrModel.showlicence"
      :reportcode="editAttrModel.reportcode"
      :reportprice="editAttrModel.reportprice"
      :reportdate="editAttrModel.reportdate"
      :estimateweight="editAttrModel.estimateweight"
      :estimatethrowweight="editAttrModel.estimatethrowweight"
      :estimatebox="editAttrModel.estimatebox"
      :clearingweight="editAttrModel.clearingweight"
      :agencyname="editAttrModel.agencyname"
      :flighttime="editAttrModel.flighttime"
    >
    </customsbill-attr>
  </div>
</template>

<script>
import { PAGESIZELIST, PAGE_LAYOUT, PAGESIZE } from 'config/index'
import Customsbill from 'api/customsbill'
import commonApi from 'api/common'
import { GetToken } from 'utils/auth'
import CustomsbillAttr from 'components/customsbill-attr'
import CustomsBillDetail from 'components/customsbill-detail'
import tableIndex from '@/mixins/table-index'
export default {
  components: { CustomsBillDetail, CustomsbillAttr },
  name: '',
  mixins: [tableIndex],
  data() {
    return {
      currentPage: 1,
      pageSize: PAGESIZE,
      pageLayout: PAGE_LAYOUT,
      pageSizes: PAGESIZELIST,
      showCustomsBill: false, // 新增编辑提单
      showEidtAttr: false, // 编辑附件
      searchData: {
        agency: '', // 搜索代理商
        cnumber: '', // 提单号
        flightTime: '', // 航班时间
        state: [1], // 启用状态
        ishidden: [], // 启用状态
        useState: [], // 使用状态
        hasAttr: [], //  是否有提单
        hasLicence: [], // 是否有出口许可
        showLicence: [] // 是否显示出口许可
      },
      state: [
        { key: 1, value: '有效' },
        { key: 0, value: '无效' }
      ], // 启用状态
      ishidden: [
        { key: 1, value: '是' },
        { key: 0, value: '否' }
      ], // 启用状态
      useState: [
        { key: 1, value: '已使用' },
        { key: 0, value: '未使用' }
      ], // 使用状态
      hasAttr: [
        { key: 1, value: '是' },
        { key: 0, value: '否' }
      ], //  是否有提单
      hasLicence: [
        { key: 1, value: '是' },
        { key: 0, value: '否' }
      ], // 是否有出口许可
      showLicence: [
        { key: 1, value: '显示' },
        { key: 0, value: '隐藏' }
      ], // 是否显示出口许可
      detailModel: {},
      tableData: [],
      tableTotal: 0,
      editAttrModel: {
        filetype: '',
        cnumber: '',
        code: '',
        showlicence: '',
        files: '',
        reportcode: '',
        reportprice: '',
        estimateweight: '',
        estimatethrowweight: '',
        estimatebox: '',
        flighttime: '',
        agnecyname: ''
      },
      editModel: {
        key: '',
        propName: ''
      },
      editNumber:{
        old:'',
        new:''
      },
      activeName: 'default',
      multipleSelection: [],
      agencys: [] // 代理商
    }
  },
  computed: {},
  watch: {},
  created() {
    this.pageSize = 50
  },
  mounted() {
    this.getAgencys()
  },
  destroyed() {},
  methods: {
    handleEditNumber(old){
      this.editNumber.old = old;
      this.editNumber.new = old;
    },
    handleNumberChange(){
      if(this.editNumber.new && this.editNumber.old != this.editNumber.new)
      {
        Customsbill.setCustomsCnumber({ 
            token: GetToken(),
            ...this.editNumber
          }).then(res => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            this.tableData = this.tableData.map(item => {
                if(item.cnumber == this.editNumber.old){
                  item.cnumber = this.editNumber.new
                }
                return item
              })
            } else {
              this.$message.error(res.message)
            }
            this.editNumber = {
              old:'',
              new:''
            }
        })
      } else {
        this.editNumber = {
          old:'',
          new:''
        }
      }
    },
    // 编辑属性
    handlePropChange(model, name) {
      let _obj = { ...this.editModel }
      this.editModel = { key: '', propName: '' }
      Customsbill.eidtCustomsbillInvoice({
        token: GetToken(),
        id: _obj.key,
        name: _obj.propName,
        val: model[name] || '0'
      }).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 编辑属性
    eidtProp(model, name) {
      this.editModel.key = model.id
      this.editModel.propName = name
      console.log('click edit:', this.editModel)
    },
    // 是否屏蔽代理商提单
    handleHiddenChange(item) {
      Customsbill.customsBillHidden({
        token: GetToken(),
        id: item.id,
        hidden: item.ishidden
      }).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 点击输出许可状态
    handleLicenceChange(item) {
      Customsbill.setCustomsBillState({
        token: GetToken(),
        id: item.id,
        state: item.showlicence,
        type: 'licence'
      }).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 导出excel
    handelExportExcel() {
      let _start = '',
        _end = ''
      if (this.searchData.flightTime) {
        _start = this.searchData.flightTime[0]
        _end = this.searchData.flightTime[1]
      }
      Customsbill.DownLoadCustomsBillData({
        token: GetToken(),
        agency: this.searchData.agency,
        cnumber: this.searchData.cnumber,
        start: _start,
        end: _end,
        hidden: this.searchData.ishidden.join(','),
        state: this.searchData.state.join(','),
        useState: this.searchData.useState.join(','),
        hasAttr: this.searchData.hasAttr.join(','),
        hasLicence: this.searchData.hasLicence.join(','),
        showLicence: this.searchData.showLicence.join(',')
      })
    },
    // 列表选择
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log('multiple selection val:', this.multipleSelection)
    },
    // 批量下载
    handleDownLoadAttr(type) {
      let _ids = []
      this.multipleSelection.forEach((item) => {
        _ids.push(item.id)
      })
      if (!_ids.length) {
        this.$message.error('请勾选需要下载的记录')
        return false
      }
      console.log('ids:', _ids, _ids.join())
      Customsbill.DownFile({
        token: GetToken(),
        id: _ids.join(),
        type: type
      }).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '下载中...'
          })
          location.href = res.record
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 批量修改状态
    handleSetState(type, state) {
      console.log('state:', type)
      if (this.multipleSelection.length) {
        this.$confirm('确定要批量更改状态吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(() => {
            let _ids = []
            this.multipleSelection.forEach((item) => {
              _ids.push(item.id)
            })
            if (!_ids.length) {
              this.$message.error('请勾选需要更改状态的记录')
              return false
            }
            console.log('ids:', _ids, _ids.join())
            Customsbill.setCustomsBillState({
              token: GetToken(),
              id: _ids.join(),
              state: state,
              type: type
            }).then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                })
                this.tableData = []
                if (this.activeName == 'default') {
                  this.getLimits()
                } else {
                  this.getInfoList()
                }
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 切换列表
    handleTabClick() {
      this.currentPage = 1
      this.tableData = []
      if (this.activeName == 'default') {
        this.getLimits()
      } else {
        this.getInfoList()
      }
    },
    // 关闭
    handleCloseCustomsDetail(value) {
      this.detailModel = {}
      this.showCustomsBill = false
      if (value) {
        this.currentPage = 1
        this.tableData = []
        this.getLimits()
      }
    },
    // 新增记录
    handleAdd() {
      this.showCustomsBill = true
    },
    // 编辑
    handleEdit(row) {
      this.detailModel = JSON.parse(JSON.stringify(row))
      this.showCustomsBill = true
      console.log(row)
    },
    // 删除
    handleDel(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          Customsbill.delCustomsBill({
            id: row.id,
            token: GetToken()
          }).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getLimits()
            } else {
              this.$message({
                type: 'error',
                message: res.message
              })
            }
          })
        })
        .catch(() => {})
    },
    // 查询
    handleSearch() {
      this.currentPage = 1
      this.tableData = []
      if (this.activeName == 'default') {
        this.getLimits()
      } else {
        this.getInfoList()
      }
    },
    // 重置
    handleReset() {
      this.currentPage = 1
      this.searchData = {
        agency: '', // 搜索代理商
        cnumber: '', // 提单号
        flightTime: '', // 航班时间
        state: [], // 启用状态
        ishidden: [], // 是否屏蔽
        useState: [], // 使用状态
        hasAttr: [], //  是否有提单
        hasLicence: [], // 是否有出口许可
        showLicence: [] // 是否显示出口许可
      }
      this.tableData = []
      if (this.activeName == 'default') {
        this.getLimits()
      } else {
        this.getInfoList()
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.tableData = []
      if (this.activeName == 'default') {
        this.getLimits()
      } else {
        this.getInfoList()
      }
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.tableData = []
      if (this.activeName == 'default') {
        this.getLimits()
      } else {
        this.getInfoList()
      }
    },
    // 获取代理商
    getAgencys() {
      commonApi.getAgencys({}, false).then((res) => {
        if (res.success) {
          this.agencys = res.record
          this.getLimits()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getLimits() {
      let _start = '',
        _end = ''
      if (this.searchData.flightTime) {
        _start = this.searchData.flightTime[0]
        _end = this.searchData.flightTime[1]
      }
      Customsbill.getCustomsbillList({
        token: GetToken(),
        agency: this.searchData.agency,
        cnumber: this.searchData.cnumber,
        start: _start,
        end: _end,
        hidden: this.searchData.ishidden.join(','),
        state: this.searchData.state.join(','),
        useState: this.searchData.useState.join(','),
        hasAttr: this.searchData.hasAttr.join(','),
        hasLicence: this.searchData.hasLicence.join(','),
        showLicence: this.searchData.showLicence.join(','),
        curr: this.currentPage,
        pagesize: this.pageSize
      }).then((res) => {
        this.tableData = res.record
        this.tableTotal = +res.message
        if (res.success) {
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getInfoList() {
      let _start = '',
        _end = ''
      if (this.searchData.flightTime) {
        _start = this.searchData.flightTime[0]
        _end = this.searchData.flightTime[1]
      }
      Customsbill.getCustomsbillInfoList({
        token: GetToken(),
        agency: this.searchData.agency,
        cnumber: this.searchData.cnumber,
        start: _start,
        end: _end,
        hidden: this.searchData.ishidden.join(','),
        state: this.searchData.state.join(','),
        useState: this.searchData.useState.join(','),
        hasAttr: this.searchData.hasAttr.join(','),
        hasLicence: this.searchData.hasLicence.join(','),
        showLicence: this.searchData.showLicence.join(','),
        curr: this.currentPage,
        pagesize: this.pageSize
      }).then((res) => {
        this.tableData = res.record
        this.tableTotal = +res.message
        if (res.success) {
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 编辑附件 type 1提单 2许可
    handleEditAttr(item, type) {
      this.editAttrModel.filetype = type
      this.editAttrModel.cnumber = item.cnumber
      this.editAttrModel.code = item.code
      this.editAttrModel.showlicence = item.showlicence
      if (type == 1) {
        this.editAttrModel.files = item.attr
      } else {
        this.editAttrModel.files = item.licenceattr
      }
      this.editAttrModel.reportcode = item.reportcode
      this.editAttrModel.reportprice = item.reportprice
      this.editAttrModel.reportdate = item.reportdate
      this.editAttrModel.estimateweight = item.estimateweight
      this.editAttrModel.estimatethrowweight = item.estimatethrowweight
      this.editAttrModel.estimatebox = item.estimatebox
      this.editAttrModel.clearingweight = item.clearingweight
      this.editAttrModel.flighttime = item.flighttime.slice(0, 10)
      this.editAttrModel.agencyname = item.Agency ? item.Agency.agency_name : ''
      this.showEidtAttr = true

      console.log('edit attr:', type, item)
    },
    // 关闭编辑附件
    handleCloseEditAttr(refresh) {
      if (refresh) {
        if (this.activeName == 'default') {
          this.getLimits()
        } else {
          this.getInfoList()
        }
      }
      this.showEidtAttr = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table-children-title,
.table-children-body {
  display: flex;
  height: 44px;
  background: #f2e8ff;
  align-items: center;
  overflow: auto;
  position: relative;
  .agency,
  .useqty,
  .usestate,
  .state,
  .boxqty,
  .weight,
  .realweight,
  .bulkweight,
  .remark,
  .showlicence,
  .reportcode,
  .reportprice,
  .poll,
  .licenceattr {
    width: 200px;
    padding: 0 10px;
    border-right: 1px solid #ddd;
    height: 44px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  .agency {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    line-height: 44px;
    width: 219px;
  }
  .useqty,
  .usestate,
  .state,
  .boxqty,
  .realweight,
  .bulkweight,
  .poll,
  .reportcode,
  .reportprice,
  .showlicence {
    width: 160px;
  }

  .weight {
    width: 140px;
  }
  .remark {
    width: 240px;
  }
  .boxqty {
    margin-right: 130px;
    border-right: 0;
  }
  .licenceattr {
    width: auto;
    min-width: 300px;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .operation {
    border-left: 1px solid #ddd;
    padding: 0 10px;
    width: 130px;
    position: absolute;
    height: 44px;
    display: flex;
    align-items: center;
    right: 0;
    color: #68488f;
    font-weight: bold;
    font-size: 12px;
    .edit {
      margin-right: 10px;
      cursor: pointer;
    }
    .del {
      cursor: pointer;
    }
  }
}
.table-children-body {
  background: #f9f9f9;
}
.table-childred-body {
  display: flex;
}
.pdf-items {
  display: flex;
  flex-wrap: wrap;
  .attr-info {
    margin-right: 10px;
  }
}
.attr-info {
  .icon-PDF {
    font-size: 13px;
    color: #666;
  }
  .icon-PDF.info {
    font-size: 24px;
    color: green;
  }
  a {
    text-decoration: none;
  }
}
.icon-shangchuan {
  color: #68488f;
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    font-size: 13px;
  }
}
.table-line-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content {
    display: flex;
  }
  .el-icon-edit {
    cursor: pointer;
    font-size: 16px;
    color: #68488f;
  }
}
.bill-invoice {
  font-size: 16px;
  div {
    margin-bottom: 5px;
  }
  .title {
    font-weight: bold;
  }
  span {
    display: inline-block;
    margin-left: 5px;
  }
}
::v-deep {
  .el-table__cell.el-table__expanded-cell {
    padding: 0;
  }
  .el-tabs {
    background: #fff;
  }
}
</style>
