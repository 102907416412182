<!-- 上传附件 -->
<template>
  <div>
    <el-dialog
      title=""
      :visible="true"
      width="650px"
      :before-close="handleClose"
    >
      <div class="customsbill-base">
        <el-alert :title="agencyname" type="warning" :closable="false"
          >
          <div class="base-text">
            <div>提单号：<span>{{ cnumber }}</span></div>
            <div>航班日期：<span>{{ flighttime }}</span></div>
          </div>
        </el-alert>
      </div>
      <div class="upload-page">
        <el-form
          :model="modelData"
          label-width="100px"
          :rules="rules"
          ref="modelForm"
          :inline="true"
        >
          <template v-if="filetype == 2">
            <el-form-item label="申告番号" prop="reportcode">
              <el-input
                v-model="modelData.reportcode"
                placeholder="输入申告番号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="申告金额" prop="reportprice">
              <el-input
                v-model="modelData.reportprice"
                placeholder="输入申告金额"
                @blur="handleBlurReportPrice"
                @focus="handleFocusReportPrice"
                clearable
                ><template slot="append">円</template></el-input
              >
            </el-form-item>
            <el-form-item label="申告日期" prop="reportdate">
              <el-date-picker
                v-model="modelData.reportdate"
                type="date"
                placeholder="请选择申告日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="提单实重" prop="estimateweight">
              <el-input
                v-model="modelData.estimateweight"
                placeholder="输入提单实重"
                clearable
                size="20"
                ><template slot="append">kg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="提单抛重" prop="estimatethrowweight">
              <el-input
                v-model="modelData.estimatethrowweight"
                placeholder="输入提单抛重"
                clearable
                size="20"
                ><template slot="append">kg</template></el-input
              >
            </el-form-item>
            <el-form-item label="货代结算重量" prop="clearingweight">
              <el-input
                v-model="modelData.clearingweight"
                placeholder="输入货代结算重量"
                clearable
                ><template slot="append">kg</template></el-input
              >
            </el-form-item>
            <el-form-item label="提单箱数" prop="estimatebox">
              <el-input
                v-model="modelData.estimatebox"
                placeholder="输入提单箱数"
                clearable
                size="20"
              ></el-input>
            </el-form-item>
          </template>
        </el-form>

        <div class="attr-box">
          <div>
            <el-upload
              v-if="!disabled"
              class="upload-demo"
              drag
              action=""
              :multiple="multiple"
              :http-request="handleUpload"
              :show-file-list="false"
              :accept="accept"
              :on-change="beforeAvatarUpload"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                {{ explain }}
              </div>
            </el-upload>
          </div>
          <div class="attr-item">
            <div class="show-attr" v-if="filetype == 2">
              显示输出许可
              <el-switch
                v-model="modelData.showlicence"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </div>

            <div class="file-item" v-if="attrTmp && attrTmp.length">
              <div class="file" v-for="(item, index) in attrTmp" :key="index">
                <div>
                  {{ index + 1 }}
                  <a :href="item.url" target="_blank"
                    ><i class="icon iconfont icon-PDF red"></i
                    >{{ item.name }}</a
                  >
                </div>
                <i
                  class="icon iconfont icon-shanchu2 red"
                  @click="handleDelFile(index, 1)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { vxRule, rules } from '@/utils/validator'
import Customsbill from 'api/customsbill'
import commonApi from 'api/common'
import { GetToken } from 'utils/auth'
export default {
  components: {},
  name: '',
  props: {
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    explain: { type: String, default: '只能上传pdf文件，且不超过2M' },
    accept: { type: String, default: '.pdf' },
    filetype: {
      type: Number,
      default: 0
    },
    cnumber: { type: String, default: '' },
    code: { type: String, default: '' },
    files: { type: String, default: '' },
    showlicence: { type: Number, default: 0 },

    reportcode: { default: '' },
    reportprice: { default: '' },
    reportdate: { default: '' },
    estimateweight: { default: '' },
    estimatethrowweight: { default: '' },
    estimatebox: { default: '' },
    clearingweight: { default: '' },
    agencyname: { default: '' },
    flighttime: { default: '' }
  },
  data() {
    let verifyreportprice = (rule, value, callback) => {
      try {
        if (this.oldPrice) {
          const regex = /^-?\d+(\.\d{1,2})?$/
          if (!regex.test(this.oldPrice)) {
            callback(new Error('只能输入数字，最多两位小数'))
            this.isNewGoods = false
          } else {
            callback()
          }
        } else {
          callback()
        }
      } catch (error) {}
    }
    return {
      rules: {
        reportprice: [{ validator: verifyreportprice }],
        estimateweight: vxRule(false, 'Float2'),
        estimatethrowweight: vxRule(false, 'Float2'),
        estimatebox: vxRule(false, 'Int'),
        clearingweight: vxRule(false, 'Float2')
      },
      modelData: {},
      attrTmp: [],
      oldPrice: ''
    }
  },
  computed: {},
  watch: {},
  created() {
    this.modelData = {
      multiple: this.multiple,
      disabled: this.disabled,
      explain: this.explain,
      accept: this.accept,
      filetype: this.filetype,
      cnumber: this.cnumber,
      code: this.code,
      files: this.files,
      showlicence: this.showlicence,

      reportcode: this.reportcode,
      reportprice: this.reportprice,
      reportdate: this.reportdate,
      estimateweight: this.estimateweight,
      estimatethrowweight: this.estimatethrowweight,
      estimatebox: this.estimatebox,
      clearingweight: this.clearingweight
    }
    this.oldPrice = this.modelData.reportprice
    this.handleBlurReportPrice()
    console.log(this.modelData)
    if (this.files) {
      this.attrTmp = JSON.parse(this.files) || []
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    handleFocusReportPrice() {
      this.modelData.reportprice = this.oldPrice
    },
    handleBlurReportPrice() {
      this.oldPrice = this.modelData.reportprice
      const regex = /^-?\d+(\.\d{1,2})?$/
      if (regex.test(this.modelData.reportprice)) {
        this.modelData.reportprice = this.numFormat(this.modelData.reportprice)
      }
    },
    numFormat(num) {
      if (num) {
        num = num.toString().split('.')
        const arr = num[0].split('').reverse()
        let res = []
        for (let i = 0; i < arr.length; i++) {
          if (i % 3 === 0 && i !== 0) {
            res.push(',')
          }
          res.push(arr[i])
        }
        res.reverse()
        if (num[1]) {
          res = res.join('').concat('.' + num[1])
        } else {
          res = res.join('')
        }
        return res
      } else {
        return ''
      }
    },
    beforeAvatarUpload(file) {
      // console.log('before avatar upload', file)

      const is1M = file.size / 1024 / 1024 < 2 // 限制小于5M
      const pdf = file.raw.type === 'application/pdf'
      if (!pdf) {
        this.$message.error('上传文件只能是pdf格式!')
      }
      if (!is1M) {
        this.$message.error('图片大小不可超过2MB')
      }
      return is1M && pdf
    },
    // 上传图片
    handleUpload(res) {
      var file = res.file
      var name = res.file.name
      commonApi.uploadImage({ file }).then((res) => {
        if (res.data.state) {
          this.attrTmp.push({
            name: name,
            url: res.data.data
          })
          console.log('this attr:', this.attrTmp)
        }
      })
    },
    // 关闭
    handleClose() {
      this.$emit('FUNCLOSEATTR')
    },
    // 删除文件
    handleDelFile(index) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.attrTmp.splice(index, 1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {})
    },
    // 提交
    handleSubmit() {
      console.log(123)
      this.$refs.modelForm.validate((valid) => {
        console.log('valid', valid)
        if (valid) {
          Customsbill.customsBillAttr({
            token: GetToken(),
            filetype: this.modelData.filetype,
            code: this.modelData.code,
            cnumber: this.modelData.cnumber,
            attr: JSON.stringify(this.attrTmp),
            showlicence: this.modelData.showlicence,
            reportcode: this.modelData.reportcode,
            reportdate: this.modelData.reportdate,
            reportprice: this.oldPrice,
            estimateweight: this.modelData.estimateweight,
            estimatethrowweight: this.modelData.estimatethrowweight,
            estimatebox: this.modelData.estimatebox,
            clearingweight: this.modelData.clearingweight
          }).then((res) => {
            if (res.success) {
              this.$emit('FUNCLOSEATTR', true)
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.customsbill-base {
  font-size: 16px;
  margin-bottom: 20px;
  .base-text{
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    span{
      font-weight:bold;
    }
    &>div:last-child{
      margin-left: 20px;;
    }
  }
}
.upload-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .attr-item {
    flex: 1;
    margin-left: 20px;
  }
  .show-attr {
    margin-bottom: 20px;
  }
  .attr-box {
    display: flex;
    justify-content: space-between;
  }
  .input-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .ib-box {
      width: 40%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .ibb-title {
        width: 80px;
      }
    }
  }
}
.file-item {
  background: #f8f8f8;
  padding: 10px;
  .file {
    display: flex;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    & > div {
      i {
        margin-right: 5px;
      }
      a {
        margin-left: 5px;
      }
    }
  }
}
::v-deep {
  .el-input,
  .el-textarea {
    width: 190px;
  }
  .el-upload-dragger {
    width: 300px;
  }
}
</style>
