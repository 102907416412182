<!-- 上传附件 -->
<template>
  <div class="upload-page">
    <el-upload
      v-if="!disabled"
      class="upload-demo"
      drag
      action=""
      :multiple="multiple"
      :http-request="handleUpload"
      :show-file-list="false"
      :accept="accept"
      :on-change="beforeAvatarUpload"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">
        {{ explain }}
      </div>
    </el-upload>
  </div>
</template>

<script>
import Common from '../api/common'
export default {
  components: {},
  name: '',
  props: {
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    explain: { type: String, default: '只能上传jpeg,jpg,png文件，且不超过2M' },
    accept: { type: String, default: 'image/*' },
    filetype: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    beforeAvatarUpload(file) {
      // console.log('before avatar upload', file)
      const is1M = file.size / 1024 / 1024 < 2 // 限制小于5M
      const pdf = file.raw.type === 'application/pdf'
      if (!pdf) {
        this.$message.error('上传文件只能是pdf格式!')
      }
      if (!is1M) {
        this.$message.error('图片大小不可超过2MB')
      }
      return is1M && pdf
    },
    // 上传图片
    handleUpload(res) {
      console.log('handle upload:', res)
      var file = res.file
      let name = res.file.name
      Common.uploadImage({ file }).then((res) => {
        if (res.data.state) {
          this.$emit('SETFILE', {
            name: name,
            filetype: this.filetype,
            url: res.data.data
          })
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.upload-page {
  display: flex;
}
</style>
